<template>
<div class="buy-button-wrapper">
  <div v-if="showQuantityInput" class="quantity">
    <button 
      @click="quantityInput.decreaseQuantity()"
      type="button" 
      aria-label="Decrease quantity"
    >
      <MinusIcon />
    </button>
    
    <div v-if="showQuantityInput" class="input-wrapper">
      <ProductQuantityInput 
        v-model="quantity"
        aria-label="Antal"
        ref="quantityInput"
        :min="props.lowestNumber"
        :hide-spin-buttons="true"
        @focus="cachedQuantity = quantity"
        @blur="handleInputBlur"
      />
      {{ props.unit }}
    </div>

    <button
      @click="quantityInput.increaseQuantity()"
      type="button"
      aria-label="Increase quantity"
    >
      <PlusIcon />
    </button>
  </div>


  <!-- <div v-if="showQuantityInput" class="input-wrapper">
    <ProductQuantityInput 
      v-model="quantity"
    />
    {{ props.unit }}
  </div> -->

  <div class="button-wrapper">
    <button
      @click="buyProduct"
      type="button"
      class="button primary expanded"
      :disabled="props.disabled"
    >
      {{ buyButtonText }}
    </button>
  </div>
</div>

<div v-if="errMsg" class="buy-button-error-wrapper">
  <span class="err">{{ errMsg }}</span>
</div>
</template>

<script setup>
import { ref, computed, inject } from 'vue';
import { useShoppingCartStore } from '@/stores/shopping-cart.js';
import ProductQuantityInput from '@/components/form/ProductQuantityInput.vue';
import PlusIcon from '@/components/icons/PlusIcon.vue';
import MinusIcon from '@/components/icons/MinusIcon.vue';

const shoppingCartStore = useShoppingCartStore();
const quantityInput = ref();
const cachedQuantity = ref();

const { $translate } = inject('i18n');

const props = defineProps({
  productId: {
    required: false,
    type: Number
  },
  showQuantityInput: {
    required: false,
    type: [Boolean, Number],
    default: false
  },
  buttonText: {
    required: false,
    type: String
  },
  unit: {
    type: String,
    required: false,
    default: ''
  },
  disabled: {
    required: false,
    type: Boolean,
    default: false
  },
  lowestNumber: {
    required: false,
    type: Number,
    default: 1
  }
});

const productId = ref(props.productId);
const quantity = ref(props.lowestNumber || 1);
const errMsg = ref('');

const buyButtonText = computed(() => {
  let buttonText = props.buttonText || 'IMAGE_BUTTON_IN_CART';

  if (props.disabled) {
    return $translate('OUT_OF_STOCK');
  }
  return $translate(buttonText);  //$translate('PRODUCT_BUTTON_BUY')
});

const buyProduct = () => {
  const product = {
    id: productId.value,
    quantity_diff: quantity.value,
    attributes: {}
  };

  console.log('buyProduct', product);

  if (document.querySelector('form[name="add_product"]')) {
    const form = document.querySelector('form[name="add_product"]');
    const formData = new FormData(form);

    for (const [key, value] of formData) {
      product.attributes[key] = value;
    }
  }

  shoppingCartStore.setProducts(product, true)
    .catch(error => {
      errMsg.value = error;

      setTimeout(() => {
        errMsg.value = null;
      }, 5000);
    })
}
</script>