<template>
  <div class="cart-row">
    <button 
      @click="shoppingCartStore.removeProduct(product.id)" 
      class="delete" 
      aria-label="Remove product"
    >
      <TrashCanIcon />
    </button>

    <!-- Product image -->
    <div class="image">
      <a :href="product.href" aria-label="product name">
        <Image
          :source="product.image.image"
          :estimated-viewport-widths="{small: 40, medium: 20, large: 20}"
        />
      </a>
    </div>
    <div class="cart-info">
      <div class="product-info">
        
        <!-- Product title -->
        <div v-if="product.manufacturer_name" class="product-title">
          <a :href="product.href">{{ product.manufacturer_name }}</a>
        </div>
        <p>{{ product.name.split("|")[0] }}</p>

		<div class="variants-container">
			<template v-for="colorTag in colorTags" :key="colorTag">
				<div v-if="colorTag === product.variants[0]?.choice.name" class="colors">
					<div :class="[colorTag.replace('å', 'a').replace('ä', 'a').replace('ö', 'o').toLowerCase(), 'tag']"></div>
            		<span>{{ colorTag }}</span>
				</div>
			</template>
          <!-- Check for tag 425 -->
          <div v-if="product.tags.includes(425)" class="tag-container">
            <span><p>0.5 m</p></span>
          </div>
          <!-- Check for tag 424 -->
          <div v-if="product.tags.includes(424)" class="tag-container">
            <span><p>0.8 m</p></span>
          </div>
          <!-- Check for tag 422 -->
          <div v-if="product.tags.includes(422)" class="tag-container">
            <span><p>1.0 m</p></span>
          </div>
          <!-- Check for tag 418 -->
          <div v-if="product.tags.includes(418)" class="tag-container">
            <span><p>1.2 m</p></span>
          </div>
          <!-- Check for tag 417 -->
          <div v-if="product.tags.includes(417)" class="tag-container">
            <span><p>1.5 m</p></span>
          </div>
          <!-- Check for tag 419 -->
          <div v-if="product.tags.includes(419)" class="tag-container">
            <span><p>2.0 m</p></span>
          </div>
          <!-- Check for tag 420 -->
          <div v-if="product.tags.includes(420)" class="tag-container">
            <span><p>3.0 m</p></span>
          </div>
          <!-- Check for tag 638 -->
          <div v-if="product.tags.includes(638)" class="tag-container">
            <span><p>30W</p></span>
          </div>
          <div v-if="product.tags.includes(639)" class="tag-container">
            <span><p>45W</p></span>
          </div>
          <div v-if="product.tags.includes(640)" class="tag-container">
            <span><p>60W</p></span>
          </div>
        </div>

        <!-- Attributes-->
        <div class="attributes">
          <span v-for="(attribute, idx) in product.attributes" :key="idx">
            <b>{{ attribute.name }}</b> {{ attribute.value }}
          </span>
        </div>
      </div>

      <!-- Quantity -->
      <div class="quantity">
        <button 
          @click="quantityInput.decreaseQuantity()"
          type="button" 
          aria-label="Decrease quantity"
        >
          <MinusIcon />
        </button>
        
        <div :data-cart-quantity-id="product.id">
          <ProductQuantityInput 
            v-model="quantity"
            aria-label="Product quantity input"
            ref="quantityInput"
            :hide-spin-buttons="true"
            @quantity-change="updateCart"
            @focus="cachedQuantity = quantity"
            @blur="handleInputBlur"
          />
        </div>

        <button
          @click="quantityInput.increaseQuantity()"
          type="button"
          aria-label="Increase quantity"
        >
          <PlusIcon />
        </button>
      </div>

      <!-- Price -->
      <div class="price-wrapper">
        <div
          v-if="hasDiscountPrice" 
          class="price"
          :class="{ old: hasDiscountPrice }"
        >
          {{ product.total_price }}
        </div>

        <div 
          class="price"
          :class="{ new: hasDiscountPrice }"
        >
          {{ product.total_final_price }}
        </div>
      </div>
      
    </div>    
  </div>
</template>

<script setup>
import { ref, computed, watch } from 'vue';
import { debounce } from '@/composables/debounce';
import { useShoppingCartStore } from '@/stores/shopping-cart.js';
import PlusIcon from '@/components/icons/PlusIcon.vue';
import MinusIcon from '@/components/icons/MinusIcon.vue';
import TrashCanIcon from '@/components/icons/TrashCanIcon.vue';
import Image from '@/components/Image.vue';
import ProductQuantityInput from '@/components/form/ProductQuantityInput.vue';

const shoppingCartStore = useShoppingCartStore();
const props = defineProps(['product']);
const quantityInput = ref();
const cachedQuantity = ref();

const product = computed(() => {
  return props.product;
});

const quantity = ref(props.product.quantity);

const hasDiscountPrice = computed(() => {
  if (product.value.total_final_price_raw < product.value.total_price_raw) {
    return true;
  }
  return false;
});

const handleInputBlur = () => {
  if (quantity.value !== cachedQuantity.value) {
    updateCart(quantity.value);
  }
}

watch(product, () => {
  quantity.value = product.value.quantity;
});

const updateCart = (quantity) => {
  debounce(() => {
    const productData = {
      id: product.value.id,
      quantity: parseInt(quantity)
    };

    shoppingCartStore.setProducts(productData);
  }, 500);
};

// Array of colors
const colorTags = [
	"Vit", "White", 
	"Svart", "Black", 
	"Grön", "Green", 
	"Grå", "Gray", 
	"Röd", "Red", 
	"Blå", "Blue"
];
</script>